.row td {
  vertical-align: middle !important;
}

.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom {
  min-width: 160px;
  max-width: 160px;
  width: 160px;
}

.row:hover .button {
  opacity: 1;
  visibility: visible;
}

.row td {
  overflow: hidden;
  white-space: nowrap;
}

.row td:nth-of-type(5) {
  overflow: visible;
}

.row td:nth-of-type(2) {
  text-align: center;
}

.cellWidth26 {
  min-width: 26px;
  max-width: 26px;
  width: 26px;
}

.cellWidth28 {
  min-width: 28px;
  max-width: 28px;
  width: 28px;
}

.cellWidth50 {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.cellWidth80 {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
}

.cellWidth100 {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}

.cellWidth115 {
  min-width: 115px;
  max-width: 115px;
  width: 115px;
}

.cellWidth160 {
  min-width: 160px;
  max-width: 160px;
  width: 160px;
}

.cellWidth200 {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}

.cellWidth240 {
  min-width: 240px;
  max-width: 240px;
  width: 240px;
}

.cellWidth400 {
  min-width: 400px;
  max-width: 400px;
  width: 400px;
}

.row td:nth-of-type(16),
.row td:nth-of-type(17),
.row td:nth-of-type(18),
.row td:nth-of-type(19) {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}

.scroll {
  margin-bottom: 8rem;
}

.table {
  height: 100%;
}
