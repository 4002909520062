.row td {
  width: 1%;
  vertical-align: middle !important;
}

.row td:nth-of-type(3),
.row td:nth-of-type(4) {
  min-width: 120px;
  max-width: 120px;
  width: 120px;
}

.row td:last-child {
  width: auto;
  max-width: 200px;
}
