.container {
  max-width: 500px;
  padding: 10px 0;
}

.list {
  padding-inline-start: 27px;
}

.font_p {
  font-size: 19px;
  line-height: 1.4;
}

.button {
  width: 100px;
  height: 33px;
}

.button:hover {
  opacity: 0.6;
  color: var(--light);
}

@media (max-width: 767px) {
  .container {
    padding-left: 50px;
  }
}
