.row {
  color: var(--text-color);
  white-space: nowrap;
}

.row:hover {
  text-decoration: none;
  color: var(--light);
  background-color: var(--primary-500);
}
