.name {
  color: var(--brand-500);
  background-color: var(--gray-50);
  border: none;
}

.name:hover {
  color: var(--brand-500);
}

.name:hover svg {
  fill: var(--brand-500);
}

.no_pointer {
  cursor: default;
}

.list {
  min-width: 15rem;
}

.scroll {
  max-height: 19.5rem;
  overflow-y: scroll;
}

.new {
  color: var(--text-color);
  white-space: nowrap;
  background-color: var(--light);
  border: none;
}

.new:hover {
  text-decoration: none;
  color: var(--light);
  background-color: var(--primary-500);
}

.new:hover svg {
  fill: var(--light);
}

.divider {
  width: 100%;
  height: 1px;
  margin: 0;
  overflow: hidden;
  background-color: var(--muted-200);
}

.container {
  top: 2rem;
  min-width: 16rem;
}

